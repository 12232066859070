import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { Layout, PrivacyPolicy } from '../components';

const PrivacyPolicyPage = () => (
  <Layout>
    <AntdLayout.Content>
      <PrivacyPolicy />
    </AntdLayout.Content>
  </Layout>
);

export default PrivacyPolicyPage;
